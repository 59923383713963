import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import Link from './link'

import kbusLogoPositivo from '../assets/images/logo/logo_tbh.png'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const menuCopies = props.menu;

  return (
    <Navbar light expand="md" className="navbar-custom">
      <Container className="pt-4 pb-3 align-items-end">
        <NavbarToggler onClick={toggle} />
        <Link to="/" className="navbar-brand">
          <img src={kbusLogoPositivo} alt="Buruntzaldea Bus" height="50px"/>
        </Link>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                {menuCopies.lineasYHorarios}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/proximo-bus" className="nav-link" activeClassName="font-weight-bold">{menuCopies.proximoBus}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/calcula-tu-ruta" className="nav-link" activeClassName="font-weight-bold">{menuCopies.calculaTuRuta}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/parada-mas-cercana" className="nav-link" activeClassName="font-weight-bold">{menuCopies.paradaMasCercana}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/lineas-y-horarios" className="nav-link" activeClassName="font-weight-bold">{menuCopies.lineasYHorarios}</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <NavItem>
              <Link to="https://www.lurraldebus.eus/info/tarifas_zonas" className="nav-link" activeClassName="font-weight-bold">{menuCopies.tarifas}</Link>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                {menuCopies.sobreTBH}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/quien-somos" className="nav-link" activeClassName="font-weight-bold">{menuCopies.quienSomos}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/accesibilidad" className="nav-link" activeClassName="font-weight-bold">{menuCopies.accesibilidad}</Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                {menuCopies.atencionAlUsuario}
              </DropdownToggle>
              <DropdownMenu>
                {/*
                <DropdownItem>
                  <Link to="/canales-de-contacto" className="nav-link" activeClassName="font-weight-bold">{menuCopies.canalesDeContacto}</Link>
                </DropdownItem>
                */}
                <DropdownItem>
                  <Link to="/quejas-y-sugerencias" className="nav-link" activeClassName="font-weight-bold">{menuCopies.quejasYSugerencias}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/objetos-perdidos" className="nav-link" activeClassName="font-weight-bold">{menuCopies.objetosPerdidos}</Link>
                </DropdownItem>
                {/*
                <DropdownItem>
                  <Link to="/pago-de-infracciones" className="nav-link" activeClassName="font-weight-bold">{menuCopies.pagoDeInfracciones}</Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/normativa" className="nav-link" activeClassName="font-weight-bold">{menuCopies.normativa}</Link>
                </DropdownItem>
                */}
              </DropdownMenu>
            </UncontrolledDropdown>

            {/*
            <NavItem>
              <Link to="/contacto" className="nav-link" activeClassName="font-weight-bold">{menuCopies.contacto}</Link>
            </NavItem>
            */}

            <NavItem className="d-block d-md-none mt-3 pt-3 border-top">
              <span role="button" tabIndex={0} className={`${props.activeLang === 'eus' ? 'font-weight-bold': ''} no-link-decoration`} onClick={() => {props.changeLang('eus')}} onKeyDown={() => {props.changeLang('eus')}}>EUS</span>
              <span className="px-2">|</span>
              <span role="button" tabIndex={0} className={`${props.activeLang === 'esp' ? 'font-weight-bold': ''} no-link-decoration`} onClick={() => {props.changeLang('esp')}} onKeyDown={() => {props.changeLang('eus')}}>ESP</span>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
