import React from 'react';
import {
  Container,
} from 'reactstrap';
import Link from './link'
import {FaBus, FaMapPin, FaRoute} from "react-icons/fa";

const SubHeader = (props) => {
  const menuCopies = props.menu;

  return (
    <Container className="py-1 mt-3 mb-0 d-flex flex-row justify-content-center align-items-center kbus-subheader">
      <Link to="/proximo-bus" className="small text-uppercase mx-2 mx-lg-4" activeClassName="">
        <FaBus size={"19"} style={{color: "#F69F06"}} className="mx-2 my-1 d-inline d-lg-none"/>
        <FaBus size={"14"} style={{color: "#F69F06"}} className="mr-1 pb-1 d-none d-lg-inline"/>
        <span className="d-none d-lg-inline">{menuCopies.proximoBus}</span>
      </Link>
      <Link to="/calcula-tu-ruta" className="small text-uppercase mx-2 mx-lg-4" activeClassName="">
        <FaRoute size={"19"} style={{color: "#F69F06"}} className="mx-2 my-1 d-inline d-lg-none"/>
        <FaRoute size={"14"} style={{color: "#F69F06"}} className="mr-1 pb-1 d-none d-lg-inline"/>
        <span className="d-none d-lg-inline">{menuCopies.calculaTuRuta}</span>
      </Link>
      <Link to="/parada-mas-cercana" className="small text-uppercase mx-2 mx-lg-4" activeClassName="">
        <FaMapPin size={"19"} style={{color: "#F69F06"}} className="mx-2 my-1 d-inline d-lg-none"/>
        <FaMapPin size={"14"} style={{color: "#F69F06"}} className="mr-1 pb-1 d-none d-lg-inline"/>
        <span className="d-none d-lg-inline">{menuCopies.paradaMasCercana}</span>
      </Link>
    </Container>
  );
}

export default SubHeader;
