/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SubFooter from "./subFooter"
import "../assets/stylesheets/layout.scss"
import "../assets/stylesheets/navbar.scss"
import {ThemeProvider} from 'styled-components'
import theme from '../assets/stylesheets/theme'
import Banner from './banner'
import {LangContext} from "./lurraldebusWrapper";
import SubHeader from "./subheader";

const Layout = ({children}) => {

  const data = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
        }
      }
      menu: strapiMenu {
        proximoBus
        lineasYHorarios
        tarifas
        sobreTBH
        quienSomos
        accesibilidad
        atencionAlUsuario
        canalesDeContacto
        quejasYSugerencias
        objetosPerdidos
        pagoDeInfracciones
        normativa
        contacto
        calculaTuRuta
        paradaMasCercana
      }
      menu_eus: strapiMenuEus {
        proximoBus
        lineasYHorarios
        tarifas
        sobreTBH
        quienSomos
        accesibilidad
        atencionAlUsuario
        canalesDeContacto
        quejasYSugerencias
        objetosPerdidos
        pagoDeInfracciones
        normativa
        contacto
        calculaTuRuta
        paradaMasCercana
      }
    }
  `)

  let {title} = data.site.siteMetadata

  return (
    <ThemeProvider theme={theme}>
      <LangContext.Consumer>
        {context => (
          <div id="buruntzaldea-content" style={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: "column"
          }}>
            <Banner changeLang={context.changeLang} activeLang={context.activeLang}/>
            <Header siteTitle={title} menu={context.translate(data, 'menu', '')} activeLang={context.activeLang}
                    changeLang={context.changeLang}/>
            <SubHeader menu={context.translate(data, 'menu', '')}/>
            <main style={{flexGrow: 1}}>
              {children}
            </main>
            <Footer menuCopies={context.translate(data, 'menu', '')}/>
            <SubFooter title={title}/>
          </div>
        )}
      </LangContext.Consumer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
